import React from "react";
import { styled } from "goober";

import { appURLs } from "@/app/constants.mjs";
import Ignite from "@/game-apex/articles/ignite-patch-notes.mdx";
import PostMalone from "@/game-apex/articles/post-malone.mdx";
import Upheaval from "@/game-apex/articles/upheaval.mdx";
import GameArticle, { ArticleCard } from "@/shared/GameArticle.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const PATCH_TO_PATCH_NOTES_MAP = {
  "post-malone-event": {
    title: [
      "apex:meta.patchNotes.postMalone.title",
      "Apex Legends: Post Malone Event",
    ],
    subTitle: [
      "apex:patchNotes.postMalone.title",
      "Apex Legends: Post Malone Event",
    ],
    description: [
      "apex:meta.patchNotes.postMalone.description",
      "Check out the Apex Legends X Post Malone Event details",
    ],
    content: PostMalone,
    date: new Date("Nov 07, 2023 10:00:00"),
    imageURL: `${appURLs.CDN}/blitz/apex/articles/post-malone.webp`,
  },
  ignite: {
    title: [
      "apex:meta.patchNotes.ignite.title",
      'Apex Legends: Season 19 "Ignite" Patch Notes',
    ],
    subTitle: [
      "apex:patchNotes.ignite.title",
      "Apex Legends: Ignite Patch Notes",
    ],
    description: [
      "apex:meta.patchNotes.ignite.description",
      "Check out the new map updates coming with Season 19, and meet the latest Legend, Conduit in Apex Legends Season 19!",
    ],
    content: Ignite,
    date: new Date("Oct 27, 2023 10:00:00"),
    imageURL: `${appURLs.CDN}/blitz/apex/articles/ignite-header.webp`,
  },
  upheaval: {
    title: ["apex:patchNotes.upheaval.title", "Upheaval Patch Notes"],
    subTitle: [
      "apex:patchNotes.upheaval.subTitle",
      "Apex Legends: Upheaval Patch Notes",
    ],
    description: [
      "apex:meta.patchNotes.upheaval.description",
      "Ready yourself for Upheaval’s launch with balance and Legend updates, insight into Alter, and more.",
    ],
    content: Upheaval,
    date: new Date("May 6, 2024 10:00:00"),
    imageURL: `${appURLs.CDN}/blitz/apex/articles/upheaval.webp`,
  },
};

const Card = styled(ArticleCard)`
  iframe {
    aspect-ratio: 16 / 9;
    border-radius: var(--br-lg);
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--sp-5);
    max-width: 100%;
    width: 550px;
  }

  a {
    color: var(--primary);
    text-decoration: underline;
  }
`;

function PatchNotes() {
  const { parameters } = useRoute();
  const patch = parameters[0];
  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return (
    <GameArticle
      articleHeader={{
        title: content.title,
        subTitle: content.subTitle,
        date: content.date,
        imageURL: content.imageURL,
      }}
      ContentMDX={content.content}
      components={{ Card }}
    />
  );
}

export default PatchNotes;

export const meta = (parameters) => {
  const patch = parameters[0];

  const content =
    PATCH_TO_PATCH_NOTES_MAP[patch] ||
    Object.values(PATCH_TO_PATCH_NOTES_MAP).sort(
      (a, b) => b.date.getTime() - a.date.getTime(),
    )[0];

  return {
    title: content.title,
    description: content.description,
    subtitle: true,
    image: {
      url: content.imageURL,
      alt: content.title,
      width: 656,
      height: 369,
    },
  };
};
