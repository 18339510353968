import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import DataTable from "@/shared/DataTable.jsx";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h3: "h3",
    img: "img",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  }, {Card} = _components;
  if (!Card) _missingMdxReference("Card", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      id: "whats-new",
      children: _jsx(_components.a, {
        href: "#whats-new",
        children: "What's New"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "The biggest partnership in Apex Legends™ history starts now, Legends. We’re squadding up with Post Malone to unleash two weeks of beautiful mayhem featuring a brand new Limited Time Mode - Three Strikes, cosmetics created in partnership with the superstar musician and inspired by his love of The Games, and more. So get ready to squad up, grab a loadout, and step into the spotlight–it’s your Time to Shine."
      }), _jsx("iframe", {
        src: "https://www.youtube.com/embed/FpWZio2BPpo",
        type: "text/html",
        frameBorder: "0",
        allowFullScreen: true
      })]
    }), "\n", _jsx(_components.h3, {
      id: "new-limited-time-mode-three-strikes",
      children: _jsx(_components.a, {
        href: "#new-limited-time-mode-three-strikes",
        children: "New Limited-Time Mode: Three Strikes"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: [_jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/third-strikes.webp#full-width",
          alt: "Cosmetics"
        }), "\nUnleash mayhem in this new LTM with faster revives and invincibility when downed. In Three Strikes, you and your squad will have three chances to be the last squad standing. Players can be knocked, but not killed unless the entire squad is wiped. At that point, you’ll lose a strike and respawn back into the map fully equipped. Lose all three strikes and you’re out."]
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "Players will respawn one POI away from where their squad wiped with the gear that was equipped at the time of the squad wipe, including:"
      }), _jsx("br", {}), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Armor and EVO progression"
        }), "\n", _jsx(_components.li, {
          children: "Weapons and attachments (except for mythic weapons)"
        }), "\n", _jsx(_components.li, {
          children: "Ammo, ordinance, and consumables"
        }), "\n"]
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "Jump in Fridays through Sundays for 1.5 times the fun with:"
      }), _jsx("br", {}), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "1.5x boost on all XP gained November 10 - November 12, 2023"
        }), "\n", _jsx(_components.li, {
          children: "1.5x boost on XP gained when playing Three Strikes November 17 - November 19, 2023"
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "cosmetics-designed-with-post-malone",
      children: _jsx(_components.a, {
        href: "#cosmetics-designed-with-post-malone",
        children: "Cosmetics Designed with Post Malone"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: [_jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/acid-wraith-store-v1.webp#full-width",
          alt: "Cosmetics"
        }), "\nOur “Iconic” tier cosmetics make their debut with skins, stickers, and bundles created in partnership with Post Malone for some of his favorite Legends including Octane, Wraith, Horizon, and Lifeline."]
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "Unlock all 20 stickers from Posty Sticker Packs† to receive 3 Legendary stickers to tat up your heals and shields."
      })]
    }), "\n", _jsx(_components.h3, {
      id: "camo-credits--reward-shop",
      children: _jsx(_components.a, {
        href: "#camo-credits--reward-shop",
        children: "Camo Credits & Reward Shop"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/acid-wraith-rewards-shop.webp#full-width",
          alt: "Rewards Shop"
        })
      }), _jsx(_components.p, {
        children: "Choose the right rewards for you with our event currency: Camo Credits. You can earn up to 285 Camo Credits per day by completing Daily Challenges and use them to unlock event cosmetics."
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: ["And cosmetics aren’t the only thing available. Camo Credits can also be used for a limited amount of Battlepass Stars and Sweepstakes Tokens**. Check out ", _jsx("a", {
          href: "https://www.ea.com/games/apex-legends/news/ignite-sweepstakes",
          target: "_blank",
          children: "Ignite Sweepstakes blog"
        }), " for all those juicy details. With only 14 days to earn Camo Credits, you won’t earn enough currency for everything and to max out on Sweepstakes Tokens—so choose wisely. Camo Credits expire at the end of the event.\nYou can also unlock four unique variants of the Apex Legends X Post Malone Event badge by completing challenges and earning currency throughout the event."]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "twitch-drops",
      children: _jsx(_components.a, {
        href: "#twitch-drops",
        children: "Twitch Drops"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: [_jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/acid-wraith-twitch-drops.webp#full-width",
          alt: "Rewards Shop"
        }), "\nTune in to select Twitch streams between November 7-21 to earn exclusive cosmetics while watching your favorite streamers dominate.\nConnect your EA account with your Twitch account, and tune in during the dates below to unlock the corresponding items. ", _jsx("a", {
          href: "https://help.ea.com/uk/help/account/link-your-console-accounts/",
          target: "_blank",
          children: "Instructions on how to link your EA account can be found here"
        }), "."]
      }), _jsx(DataTable, {
        sortable: false,
        cols: [{
          display: "Available",
          align: "center"
        }, {
          display: "Drops",
          align: "center"
        }, {
          display: "Hours Watched",
          align: "center"
        }],
        rows: [[{
          display: "November 7-14, 2023",
          value: 1
        }, {
          display: "Weapon Charm 1",
          value: "Weapon Charm 1"
        }, {
          display: "1 hour, 1 hour total",
          value: "1 hour, 1 hour total"
        }], [{
          display: "November 7-14, 2023",
          value: 2
        }, {
          display: "Holospray 1",
          value: "Holospray 1"
        }, {
          display: "1 hour, 2 hour total",
          value: "1 hour, 2 hour total"
        }], [{
          display: "November 14-21, 2023",
          value: 3
        }, {
          display: "Weapon Charm 2",
          value: "Weapon Charm 2"
        }, {
          display: "1 hour, 1 hour total",
          value: "1 hour, 1 hour total"
        }], [{
          display: "November 14-21, 2023",
          value: 2
        }, {
          display: "Holospray 2",
          value: "Holospray 2"
        }, {
          display: "1 hour, 2 hour total",
          value: "1 hour, 2 hour total"
        }], [{
          display: "November 14-21, 2023",
          value: 2
        }, {
          display: "Posty Sticker Packt",
          value: "Posty Sticker Packt"
        }, {
          display: "1 hour, 3 hour total",
          value: "1 hour, 3 hour total"
        }]],
        sortDir: "DESC",
        sortCol: 0,
        sortColTiebreak: 1,
        style: {
          width: "fit-content",
          margin: "var(--sp-12) auto auto auto"
        }
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
