import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {useState} from 'react';
function _createMdxContent(props) {
  const _components = {
    a: "a",
    br: "br",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    img: "img",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  }, {Button, ButtonGroup, Card} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!ButtonGroup) _missingMdxReference("ButtonGroup", true);
  if (!Card) _missingMdxReference("Card", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      id: "whats-new",
      children: _jsx(_components.a, {
        href: "#whats-new",
        children: "What's New"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "It’s your Time to Shine in Apex Legends™: Ignite. With her shield-based abilities, let Conduit be your guiding light for regeneration and disruption. She’s the spark your squad needs to become Apex Champions on the faster and deadlier Storm Point. This tempest of a map has been overhauled with a reduced size and fresh Points of Interest like The Pylon, ZEUS Station, and Devastated Coast."
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "Last but certainly not least, the team is excited to share that Cross Progression will be rolling out starting this season. Link your platforms to an EA Account to show off your skills no matter where you squad up."
      }), _jsx("br", {}), _jsx("iframe", {
        src: "https://www.youtube.com/embed/rf8QuG1kbAI?si=uzOb-zoQ1cDwbtru",
        type: "text/html",
        frameBorder: "0",
        allowFullScreen: true
      })]
    }), "\n", _jsx(_components.h3, {
      id: "new-legend-conduit",
      children: _jsx(_components.a, {
        href: "#new-legend-conduit",
        children: "New Legend: Conduit"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/conduit.webp",
          alt: "Conduit"
        })
      }), _jsx(_components.p, {
        children: "Conduit is like a firework–warm, bright, and short-lived. To rise quickly, Rowenna must don a combat rig made of an incomplete Monarch Titan that she knows is poisoning her. But it’s a price she’s willing to pay for her dream of being a Legend. At home, she’s the core of her large family. She smoothes over issues with her humor and sunny disposition all while hustling and sacrificing quietly–something that’s unchanged in the Games."
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "Despite the fact she’s exchanging her life and health to be Conduit, Rowenna is having fun because she knows the Apex Games. Expert, fan, supporter–she’s there and she has the replays saved and filed, and her teammates and rivals know she’s there to win!"
      })]
    }), "\n", _jsx(_components.h3, {
      id: "storm-pointer-map-update",
      children: _jsx(_components.a, {
        href: "#storm-pointer-map-update",
        children: "Storm Pointer Map Update"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "Storm Point is a map that is loved by our competitive community for its more predictable pacing and high loot quality. However, some of the elements of Storm Point that make it a great competitive map can also make it more difficult to quickly find action during casual play."
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "This brings us to the team's primary goal for this update, which is to improve the fun factor without sacrificing competitive integrity. To do this, we’ve taken the following actions:"
      }), _jsx("br", {}), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Add attractive, central POIs for players searching for combat. Wattson’s Pylon and CETO Station provide central hot drops for players looking to fight early."
        }), "\n", _jsx(_components.li, {
          children: "Reduce overall map size to improve mid-game pacing. Significant map size reductions to both the southern coastline and northern mountainous areas make these zones more attractive for early combat and reduce the overall distance between squads."
        }), "\n", _jsx(_components.li, {
          children: "Reduce friction of central rotations. With CETO Station replacing Prowler Island and a reworked Prowler-less Forbidden Zone, rotations through the center of map have never been smoother."
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "new-points-of-interest",
      children: _jsx(_components.a, {
        href: "#new-points-of-interest",
        children: "New Points of Interest"
      })
    }), "\n", _jsx("div", {
      children: (() => {
        const points = ["Wattson's Pylon", "Zeus & Ceto Station", "Echo HQ & Coastal Camp", "Devastated Camp"];
        const [selectedPoint, setSelectedPoint] = useState(0);
        return _jsxs(_Fragment, {
          children: [_jsx(ButtonGroup, {
            children: points.map((point, i) => _jsx(Button, {
              active: selectedPoint === i,
              onClick: () => setSelectedPoint(i),
              children: point
            }, point))
          }), _jsx(_components.br, {}), selectedPoint === 0 ? _jsxs(Card, {
            children: [_jsx(_components.h4, {
              children: "Wattson's Pylon"
            }), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/wattson-pylon.webp",
              alt: "Wattson's Pylon"
            }), _jsx(_components.br, {}), _jsx(_components.p, {
              children: "At the end of Arsenal, a massive storm ravaged Storm Point, forcing it offline. This was bad news for the city of Suotamo, which is largely powered by energy infrastructure on or around the island. Wattson quickly volunteered to assist, having friends with close ties to Suotamo. Wattson has since led the charge to restore power with The Pylon, in collaboration with the Syndicate."
            }), _jsx(_components.br, {}), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/wattson-pylon2.webp",
              alt: "Wattson's Pylon 2"
            }), _jsx(_components.br, {}), _jsx(_components.p, {
              children: "With The Pylon, the team’s goal was to create a dynamic hot drop where players can reliably find early combat. The team was particularly interested in tapping into the vertical, cat-and-mouse gameplay usually found on World’s Edge."
            }), _jsx(_components.br, {}), _jsx(_components.p, {
              children: "Some notable features of Wattson’s Pylon are the Charge Tower at the center of the POI and an integrated Gravity Cannon that will launch players to the reworked Forbidden Zone. Wattson’s room on the top floor is also home to some particularly interesting details."
            }), _jsx(_components.br, {}), _jsx(_components.p, {
              children: "We’re thrilled with how Wattson’s Pylon turned out, and hope you love it too!"
            })]
          }) : selectedPoint === 1 ? _jsxs(Card, {
            children: [_jsx(_components.h4, {
              children: "Zeus & Ceto Station"
            }), _jsx(_components.p, {
              children: "ZEUS and CETO Station introduce a brand-new building kit to Storm Point, constructed to support Wattson’s efforts to restore power."
            }), _jsx(_components.h5, {
              children: " Zeus Station"
            }), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/zeus.webp",
              alt: "Zeus"
            }), _jsx(_components.p, {
              children: "In the mountains near Lightning Rod, ZEUS Station is a symmetrical POI that replaces High Point. Our goal for this POI was to make dropping on the high side of the map more viable. ZEUS Station retains the singular re-deploy balloon on Storm Point, and a particularly sneaky rotation through the mountain connects this POI directly to the back of Lightning Rod."
            }), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/flank.webp"
            }), _jsx(_components.h5, {
              children: " Ceto Station"
            }), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/ceto.webp",
              alt: "Ceto"
            }), _jsx(_components.p, {
              children: "CETO Station replaces Prowler Island, which was previously a difficult rotation due to containing PvE elements in a high traffic junction. Our goal with CETO Station was to resolve the friction of this rotation while simultaneously adding an exciting, central hot drop."
            })]
          }) : selectedPoint === 2 ? _jsxs(Card, {
            children: [_jsx(_components.h4, {
              children: "Echo HQ & Coastal Camp"
            }), _jsx(_components.p, {
              children: "The aftermath of the storm has left a big mess, and ECHO has moved in to clean it up!"
            }), _jsx(_components.h5, {
              children: "Echo HQ"
            }), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/echohq.webp",
              alt: "Echo HQ"
            }), _jsx(_components.p, {
              children: "Players are already familiar with ECHO structures from Kings Canyon, so the team wanted to bring something fresh to the kit. ECHO has established its massive HQ on the coast where Gale Station previously stood. This imposing structure features a large open interior, with maintenance tunnels around the exterior for protected flanking and a central zipline for easy rooftop access."
            }), _jsx(_components.h5, {
              children: "Coastal Camp"
            }), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/camp.webp"
            }), _jsx(_components.p, {
              children: "The team’s goal for Coastal Camp was to pull in the coastline as part of our efforts to reduce the overall map size. As a result, a huge amount of playable space has been focused into a smaller area that is much better connected to nearby POIs such as Wattson’s Pylon and ECHO HQ. We’ve also introduced a convenient new mountain rotation between Barometer and Coastal Camp."
            })]
          }) : _jsxs(Card, {
            children: [_jsx(_components.h4, {
              children: "Devastated Coast"
            }), _jsx(_components.img, {
              src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/coast2.webp"
            }), _jsx(_components.p, {
              children: "At Devastated Coast, players will be able to witness the hardest hit areas of the storm and battle within the ruined remains of Fish Farms."
            }), _jsx(_components.p, {
              children: "ECHO has built a protective wall to reinforce the central river from future flooding, creating a new structure that defines the ridgeline. A building suspended by the nearby crane provides a powerful position for squads fighting in the area."
            })]
          })]
        });
      })()
    }), "\n", _jsx(_components.h3, {
      id: "imc-armories",
      children: _jsx(_components.a, {
        href: "#imc-armories",
        children: "IMC Armories"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/armory.webp",
          alt: "IMC Armories"
        })
      }), _jsx(_components.p, {
        children: "Unfortunately, the IMC Spectres failed to defend Storm Point from the storm, leaving only ravaged husks of the armories behind. Expect some high tier loot to remain – but the Spectres will no longer defend the spoils."
      })]
    }), "\n", _jsx(_components.h3, {
      id: "reworked-area-forbidden-zone",
      children: _jsx(_components.a, {
        href: "#reworked-area-forbidden-zone",
        children: "Reworked Area: Forbidden Zone"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/forbidden.webp",
          alt: "Forbidden Zone"
        })
      }), _jsx(_components.p, {
        children: "The Forbidden Zone was hit hard by lightning during the storm, destroying parts of the perimeter fence and causing the Prowlers to flee! Our goal for this area was to reduce the friction of rotating through the zone by removing the Prowlers, but also providing structures to build better fronts and defensive options when squads are forced into combat."
      })]
    }), "\n", _jsx(_components.h3, {
      id: "reworked-area-lightning-rod",
      children: _jsx(_components.a, {
        href: "#reworked-area-lightning-rod",
        children: "Reworked Area: Lightning Rod"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/lightning-rod-before.webp",
          alt: "Lightning Rod Before"
        })
      }), _jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/lightning-rod.webp",
          alt: "Lightning Rod"
        })
      }), _jsx(_components.p, {
        children: "One of the biggest opportunities we wanted to address with this update was to solve for the low number of players choosing to drop on the high side of the map. In addition to adding ZEUS Station, the team felt that it was important to reduce the overall size of this zone to help make these POIs feel better connected to one another and the rest of Storm Point."
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "And that’s just about everything! The team is looking forward to hearing your thoughts on the new Storm Point and playing with you all. See you in the ring!"
      })]
    }), "\n", _jsx(_components.h3, {
      id: "cross-progression",
      children: _jsx(_components.a, {
        href: "#cross-progression",
        children: "Cross Progression"
      })
    }), "\n", _jsx(Card, {
      children: _jsx(_components.p, {
        children: "With the launch of Season 19, we’re excited to roll out Cross Progression in a phased deployment across existing platforms in Apex Legends™. Keep an eye out for an in-game prompt to initiate the Cross Progression migration which will bring together your content and progression."
      })
    }), "\n", _jsx(_components.h3, {
      id: "ranked-update",
      children: _jsx(_components.a, {
        href: "#ranked-update",
        children: "Ranked Update"
      })
    }), "\n", _jsx(Card, {
      children: _jsx(_components.p, {
        children: "Promotional Trials are launching in Ignite. Prove your skill to earn your promotion up to the next Rank tier. Upon reaching the peak of a Rank Tier, one must complete a set of time limited skill challenges to earn their tier promotion. Promotional Trials ensures a minimum skill level for each Ranked tier."
      })
    }), "\n", _jsx(_components.h3, {
      id: "skydive-holosprays",
      children: _jsx(_components.a, {
        href: "#skydive-holosprays",
        children: "Skydive Holosprays"
      })
    }), "\n", _jsx(Card, {
      children: _jsxs(_components.p, {
        children: [_jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/561x330/blitz/apex/articles/skydive.webp",
          alt: "Sky dive"
        }), "\nHolosprays are soaring to new heights this season. Equip them to your wheel to use them while in-flight! Once deployed, Holosprays will follow your flight path for a limited time or until a Skydive Emote or different Holospray is used. Let the good taunts roll on the ground and in the skies while you’re dropping - hot or not."]
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
