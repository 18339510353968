import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h3: "h3",
    h4: "h4",
    img: "img",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  }, {Card} = _components;
  if (!Card) _missingMdxReference("Card", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      id: "whats-new",
      children: _jsx(_components.a, {
        href: "#whats-new",
        children: "What's New"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "Ready yourself for Upheaval’s launch with balance and Legend updates, insight into Alter, and more."
      }), _jsx("br", {}), _jsx("iframe", {
        src: "https://www.youtube.com/embed/JK2p-vZNfPA?si=gL1KPYwBGEE7qYEE",
        type: "text/html",
        frameBorder: "0",
        allowFullScreen: true
      })]
    }), "\n", _jsx(_components.h3, {
      id: "new-legend-alter",
      children: _jsx(_components.a, {
        href: "#new-legend-alter",
        children: "New Legend: Alter"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "Alter is an agent of chaos, primarily concerned with having fun and bringing about the end of the world. Everything is a game to her, and she is always looking for an advantage. Her kit adds an entirely unique dimension to the Apex Games, allowing her to create void passages through geo or escape through the Void to her Nexus when her enemies believe they have the upper hand. Her ability to craft unsuspecting rotations through the world will sow new levels of disorder in the ring and she can’t wait to watch the whole place burn."
      }), _jsx(_components.h3, {
        id: "passive-gift-from-the-rift",
        children: _jsx(_components.a, {
          href: "#passive-gift-from-the-rift",
          children: "PASSIVE: GIFT FROM THE RIFT"
        })
      }), _jsx(_components.p, {
        children: "Can remotely interact with a deathbox to claim one item. Cannot be a shield core."
      }), _jsx(_components.h3, {
        id: "tactical-void-passage",
        children: _jsx(_components.a, {
          href: "#tactical-void-passage",
          children: "TACTICAL: VOID PASSAGE"
        })
      }), _jsx(_components.p, {
        children: "Creates a portal passageway through a surface."
      }), _jsx(_components.h3, {
        id: "ultimate-void-nexus",
        children: _jsx(_components.a, {
          href: "#ultimate-void-nexus",
          children: "ULTIMATE: VOID NEXUS"
        })
      }), _jsx(_components.p, {
        children: "Create a regroup point that all allies can remotely interact with to open a phase tunnel back to that location."
      })]
    }), "\n", _jsx(_components.h3, {
      id: "upgrades",
      children: _jsx(_components.a, {
        href: "#upgrades",
        children: "Upgrades"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/blitz/apex/articles/upgrades.webp",
          alt: "Upgrades"
        })
      }), _jsx(_components.h3, {
        id: "level-2-upgrade-options",
        children: _jsx(_components.a, {
          href: "#level-2-upgrade-options",
          children: "Level 2 Upgrade Options"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Ultimate Cooldown -\tReduce Ultimate cooldown by 30s."
        }), "\n", _jsx(_components.li, {
          children: "Void Vision -\tExtend highlights and see health bars after exiting Tactical."
        }), "\n"]
      }), _jsx(_components.h3, {
        id: "level-3-upgrade-options",
        children: _jsx(_components.a, {
          href: "#level-3-upgrade-options",
          children: "Level 3 Upgrade Options"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Eternal Nexus -\tVoid Nexus no longer times out."
        }), "\n", _jsx(_components.li, {
          children: "Tactical Cooldown+ - Reduce Tactical cooldown by 10s."
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "solos-takeover",
      children: _jsx(_components.a, {
        href: "#solos-takeover",
        children: "Solos Takeover"
      })
    }), "\n", _jsx(Card, {
      children: _jsx(_components.p, {
        children: "In case you missed it, Solos will be taking over Duos from May 7, 2024 to June 24, 2024. We’ve taken aspects of some of our most popular modes and integrated them to keep things interesting: use your Battle Sense to detect nearby enemies, tear things up with pre-kitted weapons and attachments, and keep the fight going with auto heal and Second Chance mechanics."
      })
    }), "\n", _jsx(_components.h3, {
      id: "upheaval-map-rotation",
      children: _jsx(_components.a, {
        href: "#upheaval-map-rotation",
        children: "Upheaval Map Rotation"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/blitz/apex/articles/map-rotation.webp",
          alt: "Map Rotation"
        })
      }), _jsx(_components.p, {
        children: "The following maps will be available in Pubs and Ranked for the first half of this season:"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Broken Moon (Updated - Full breakdown ", _jsx(_components.a, {
            href: "https://www.ea.com/games/apex-legends/news/upheaval-game-updates",
            children: "here"
          }), ")"]
        }), "\n", _jsx(_components.li, {
          children: "Kings Canyon"
        }), "\n", _jsx(_components.li, {
          children: "World’s Edge"
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "apex-artifacts",
      children: _jsx(_components.a, {
        href: "#apex-artifacts",
        children: "Apex Artifacts"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: ["The pantheon of Apex Artifacts is expanding. Check it out ", _jsx(_components.a, {
          href: "https://www.ea.com/games/apex-legends/news/apex-artifacts",
          children: "here"
        }), " for all things Apex Artifacts including lore connections and customizations."]
      }), _jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/blitz/apex/articles/artifacts.webp",
          alt: "Artifacts"
        })
      })]
    }), "\n", _jsx(_components.h3, {
      id: "patch-notes",
      children: _jsx(_components.a, {
        href: "#patch-notes",
        children: "Patch Notes"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.h3, {
        id: "balance-updates",
        children: _jsx(_components.a, {
          href: "#balance-updates",
          children: "Balance Updates"
        })
      }), _jsx(_components.h4, {
        id: "care-package",
        children: _jsx(_components.a, {
          href: "#care-package",
          children: "Care Package"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Wingman returns to the floor", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Projectile size reduced to pre-care package values"
            }), "\n", _jsx(_components.li, {
              children: "Damage reduced to 45 (was 50)"
            }), "\n", _jsx(_components.li, {
              children: "Skullpiercer Elite removed"
            }), "\n", _jsx(_components.li, {
              children: "Hipfire accuracy reduced"
            }), "\n", _jsx(_components.li, {
              children: "Now takes Boosted Loader Hop-Up"
            }), "\n", _jsx(_components.li, {
              children: "No longer takes magazines as an attachment"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Devotion enters the Care Package", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "NEW Reverse Hipfire: sustained hipfire will tighten accuracy instead of widen"
            }), "\n", _jsx(_components.li, {
              children: "Damage increased to 16 (was 15)"
            }), "\n", _jsx(_components.li, {
              children: "Magazine size increased to 54 (was 48 at purple)"
            }), "\n", _jsx(_components.li, {
              children: "Reserve Ammo: 324"
            }), "\n", _jsx(_components.li, {
              children: "Empty reload time significantly reduced"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "gold-weapons-rotation",
        children: _jsx(_components.a, {
          href: "#gold-weapons-rotation",
          children: "Gold Weapons Rotation"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Nemesis Burst AR, Triple-Take, Peacekeeper, Prowler PDW, Longbow DMR"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "guaranteed-weapons-out-of-loot-bins",
        children: _jsx(_components.a, {
          href: "#guaranteed-weapons-out-of-loot-bins",
          children: "Guaranteed Weapons out of Loot Bins"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "The first loot bin opened by an unarmed player will always contain a weapon"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "retrieving-banners-from-death-boxes",
        children: _jsx(_components.a, {
          href: "#retrieving-banners-from-death-boxes",
          children: "Retrieving Banners from Death Boxes"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Collecting a banner will no longer lock the player out of critical gameplay actions", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Running, shooting, punching and reviving will all break out of the banner collect animation while still registering as a successful collection"
            }), "\n", _jsx(_components.li, {
              children: "Players can immediately interact with a Death Box a second time while the animation is playing or continue holding the interact button to collect and enter a Death Box in one flow"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "survival-items--support-bins",
        children: _jsx(_components.a, {
          href: "#survival-items--support-bins",
          children: "Survival Items + Support Bins"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Survival items now only spawn from a support bin’s tray if the team is in need"
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "weapons--attachments",
      children: _jsx(_components.a, {
        href: "#weapons--attachments",
        children: "Weapons & Attachments"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.h4, {
        id: "30-30-repeater",
        children: _jsx(_components.a, {
          href: "#30-30-repeater",
          children: "30-30 Repeater"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Skullpiercer Hop-Up removed"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "charge-rifle",
        children: _jsx(_components.a, {
          href: "#charge-rifle",
          children: "Charge Rifle"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "ADS recoil improved and stabilized"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "longbow-dmr",
        children: _jsx(_components.a, {
          href: "#longbow-dmr",
          children: "Longbow DMR"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Skullpiercer Hop-Up removed"
        }), "\n", _jsx(_components.li, {
          children: "Barrel Stabilizer attachment removed"
        }), "\n", _jsx(_components.li, {
          children: "Base recoil significantly improved"
        }), "\n", _jsx(_components.li, {
          children: "Projectile gravity reduced"
        }), "\n", _jsx(_components.li, {
          children: "ADS in and out time reduced"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "triple-take",
        children: _jsx(_components.a, {
          href: "#triple-take",
          children: "Triple-Take"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Now takes Boosted Loader Hop-Up"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "hop-ups",
        children: _jsx(_components.a, {
          href: "#hop-ups",
          children: "Hop-Ups"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Skullpiercer removed from the loot pool"
        }), "\n", _jsxs(_components.li, {
          children: ["Boosted Loader added to the loot pool", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Reloading while near empty will speed up reloading and overload the next magazine with extra rounds"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "1x-digital-threat-optic",
        children: _jsx(_components.a, {
          href: "#1x-digital-threat-optic",
          children: "1x Digital Threat Optic"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Removed from loot pool and all locked set weapons"
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "legends",
      children: _jsx(_components.a, {
        href: "#legends",
        children: "Legends"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.h4, {
        id: "ash",
        children: _jsx(_components.a, {
          href: "#ash",
          children: "Ash"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Arc Snare", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Changed to left-hand cast"
            }), "\n", _jsx(_components.li, {
              children: "No longer stows weapons or interrupts consumable use when launching"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsx(_components.li, {
          children: "Phase Breach: inspired by some Alter tech, nearby enemies will be highlighted for players traveling through the Void"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "ballistic",
        children: _jsx(_components.a, {
          href: "#ballistic",
          children: "Ballistic"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Whistler: damage from a planted smart bullet increased to 10 (was 5)"
        }), "\n", _jsxs(_components.li, {
          children: ["Upgrades", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Care Package Insight: removed"
            }), "\n", _jsx(_components.li, {
              children: "NEW Sling-Shot: improves Ballistic’s base sling weapon to a blueset at Level 2 and a purpleset at Level 3"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "bloodhound",
        children: _jsx(_components.a, {
          href: "#bloodhound",
          children: "Bloodhound"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Beast of the Hunt", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Cooldown increased to 4m (was 3m)"
            }), "\n", _jsx(_components.li, {
              children: "Knocks with Ult active no longer extend duration"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Upgrades: Level 3", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Tactical Cooldown: removed"
            }), "\n", _jsx(_components.li, {
              children: "Taste of Blood: buffed HP gain on knock to 50 (was 25)"
            }), "\n", _jsx(_components.li, {
              children: "NEW Long Hunt: knocks extend Beast of the Hunt duration"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "catalyst",
        children: _jsx(_components.a, {
          href: "#catalyst",
          children: "Catalyst"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Piercing Spikes", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Cooldown decreased to 20s (was 25s)"
            }), "\n", _jsx(_components.li, {
              children: "Long Cast upgrade integrated into base Tactical"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Dark Veil", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Dark Veil length increased to 45m (was 40m)"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Upgrades", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Long Cast: removed"
            }), "\n", _jsx(_components.li, {
              children: "Long Veil: moved to Level 2"
            }), "\n", _jsx(_components.li, {
              children: "NEW Ferro-Door: fully rebuild and reinforce missing doors with Passive."
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "caustic",
        children: _jsx(_components.a, {
          href: "#caustic",
          children: "Caustic"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Gas damage and slow now stops immediately after Caustic's squad is eliminated"
        }), "\n", _jsx(_components.li, {
          children: "Gas damage ramps from 4 → 10 max (was 5 → uncapped), damage on knocked players down to flat 4 (was 5)"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "conduit",
        children: _jsx(_components.a, {
          href: "#conduit",
          children: "Conduit"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Radiant Transfer", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Can no longer target a Revenant while Forged Shadows is active"
            }), "\n", _jsx(_components.li, {
              children: "When Revenant activates Forged Shadows, any temporary shield generation ends immediately. Revenant will keep any temporary shields generated before activating his Ultimate."
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Upgrades", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Battery Collection: removed"
            }), "\n", _jsx(_components.li, {
              children: "NEW Battpack: can stack up to 3 Shield Batteries per inventory slot (doesn’t stack with Gold Backpack)"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "crypto",
        children: _jsx(_components.a, {
          href: "#crypto",
          children: "Crypto"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Neurolink", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Network Traffic upgrade now integrated into base kit"
            }), "\n", _jsx(_components.li, {
              children: "Squadcount banners are now visible anytime the drone is in a deployed state"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Upgrades: Level 2", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Tac & Ultimate Cooldowns: removed"
            }), "\n", _jsx(_components.li, {
              children: "Network Expansion: moved to Level 2"
            }), "\n", _jsx(_components.li, {
              children: "NEW Quick Ping: improved drone handling (faster accel/decel)"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Upgrades: Level 3", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "NEW Satellite Imagery: drone scan persists for an additional 1.5s"
            }), "\n", _jsx(_components.li, {
              children: "NEW Hackathon: cut the drone cooldown in half, gain a speed boost anytime he uses his Ult or the drone is destroyed"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "fuse",
        children: _jsx(_components.a, {
          href: "#fuse",
          children: "Fuse"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Upgrades", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Big Bang: removed"
            }), "\n", _jsx(_components.li, {
              children: "NEW Ring Master: gain access to Ring Consoles"
            }), "\n", _jsxs(_components.li, {
              children: ["Scar Tissue: ", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Simplified and buffed damage mitigation to remove lingering burn effects"
                }), "\n", _jsx(_components.li, {
                  children: "Take a flat 20 damage when crossing The Motherlode instead of 37 (50% of 75 hp) over time"
                }), "\n"]
              }), "\n"]
            }), "\n", _jsx(_components.li, {
              children: "Wreckless: fixed explosive damage mitigation not being applied on continuous Knuckle Cluster hits (should only take about 32 damage from a full Knuckle Cluster now with Wreckless)"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "newcastle",
        children: _jsx(_components.a, {
          href: "#newcastle",
          children: "Newcastle"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Mobile Shield: throw anim speed increased (~2.5x faster)"
        }), "\n", _jsxs(_components.li, {
          children: ["Castle Wall", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Will now destroy incoming projectiles headed towards the front of the wall while energized. It will not destroy projectiles fired from behind the wall nor bombardments from other Legend’s Ultimate projectiles."
            }), "\n", _jsx(_components.li, {
              children: "Castle Wall energized duration increased to 1m (was 30s)"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsx(_components.li, {
          children: "Upgrade - Stronghold: increased energized duration to 3m (was 2.5m)"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "octane",
        children: _jsx(_components.a, {
          href: "#octane",
          children: "Octane"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Upgrades", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Wreckless: removed"
            }), "\n", _jsx(_components.li, {
              children: "Mad Hops moved to Level 2"
            }), "\n", _jsx(_components.li, {
              children: "Thick Skin: now Level 2 and 3, updated to take 5 less Stim damage to reflect that it can be selected twice (was -25%)"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "wattson",
        children: _jsx(_components.a, {
          href: "#wattson",
          children: "Wattson"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Upgrades", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Falling Stars: Pylons will stop spawning Arc Stars when her squad is eliminated"
            }), "\n", _jsx(_components.li, {
              children: "vSplit Circuit: no longer reduces shield regen capacity"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "wraith",
        children: _jsx(_components.a, {
          href: "#wraith",
          children: "Wraith"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Into the Void and Dimensional Rift: nearby enemies will be highlighted for players traveling through the Void"
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "maps",
      children: _jsx(_components.a, {
        href: "#maps",
        children: "Maps"
      })
    }), "\n", _jsx(Card, {
      children: _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Broken Moon shattered", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "New POIs: Cliff Side, Experimental Labs, Solar Pods, Space Port, Quarantine Zone, Underpass"
            }), "\n", _jsx(_components.li, {
              children: "Breaker Wharf updated"
            }), "\n", _jsx(_components.li, {
              children: "New rotations to switch things up and keep squads on the move"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["Added new possible Ring Console, Survey Beacon, and Crafter spawn locations to World’s Edge and Storm Point, and further evened out the probability of a prop spawning at any specific location", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsxs(_components.li, {
              children: ["World’s Edge", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Added possible Ring Console spawn locations to Mirage à Trois and Survey Camp"
                }), "\n", _jsx(_components.li, {
                  children: "Added possible Survey Beacon spawn locations to Survey Camp and The Geyser"
                }), "\n"]
              }), "\n"]
            }), "\n", _jsxs(_components.li, {
              children: ["Storm Point", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Added possible Crafter spawn location to Command Center"
                }), "\n", _jsx(_components.li, {
                  children: "Added possible Survey Beacon spawn locations to The Wall, and the unnamed POIs Northeast of Checkpoint and at the edge of the map East of Storm Catcher"
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      })
    }), "\n", _jsx(_components.h3, {
      id: "modes",
      children: _jsx(_components.a, {
        href: "#modes",
        children: "Modes"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.h4, {
        id: "solos",
        children: _jsx(_components.a, {
          href: "#solos",
          children: "Solos"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Six week Duos takeover "
        }), "\n", _jsx(_components.li, {
          children: "50 players"
        }), "\n", _jsxs(_components.li, {
          children: ["Second Chance Respawn", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Automatically respawn once if you die in the first 4 rounds"
            }), "\n", _jsx(_components.li, {
              children: "Second chance converted to EVO if unused by the round cutoff"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsx(_components.li, {
          children: "Battle Sense: HUD indicator when enemies are within 50 meters"
        }), "\n", _jsxs(_components.li, {
          children: ["Auto Heals", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Health regenerates out of combat"
            }), "\n", _jsx(_components.li, {
              children: "Auto heal starts after each kill"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsx(_components.li, {
          children: "Additional adjustments to loot pool, circle sizes, and round times to accommodate solo play"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "mixtape",
        children: _jsx(_components.a, {
          href: "#mixtape",
          children: "Mixtape"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Lockdown added to rotation: Zeus Station, Monument"
        }), "\n", _jsxs(_components.li, {
          children: ["Mixtape Map Rotations", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsxs(_components.li, {
              children: ["Default rotation", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Control: Production Yard, Thunderdome"
                }), "\n", _jsx(_components.li, {
                  children: "Gun Run: The Core, Wattson's Pylon"
                }), "\n", _jsx(_components.li, {
                  children: "Lockdown: Monument, Zeus Station"
                }), "\n", _jsx(_components.li, {
                  children: "TDM: Skulltown, Zeus Station"
                }), "\n"]
              }), "\n"]
            }), "\n", _jsxs(_components.li, {
              children: ["Mixtape rotation (5/24-5/28)", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Gun Run: The Core, Wattson’s Pylon"
                }), "\n", _jsx(_components.li, {
                  children: "Lockdown: Monument, Zeus Station"
                }), "\n", _jsx(_components.li, {
                  children: "TDM: Skulltown, Zeus Station"
                }), "\n"]
              }), "\n"]
            }), "\n", _jsxs(_components.li, {
              children: ["1st Week of Pride Month (6/1-6/7)", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Control: Barometer"
                }), "\n", _jsx(_components.li, {
                  children: "Gun Run: Wattson’s Pylon"
                }), "\n", _jsx(_components.li, {
                  children: "Lockdown: Zeus Station"
                }), "\n", _jsx(_components.li, {
                  children: "TDM: Zeus Station"
                }), "\n"]
              }), "\n"]
            }), "\n", _jsxs(_components.li, {
              children: ["Mixtape Rotation (6/11-6/17)", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Control: Barometer, Caustic Treatment"
                }), "\n", _jsx(_components.li, {
                  children: "Lockdown: Monument, Zeus Station"
                }), "\n", _jsx(_components.li, {
                  children: "TDM: Skulltown, Zeus Station"
                }), "\n"]
              }), "\n"]
            }), "\n", _jsxs(_components.li, {
              children: ["TDM 24/7 (6/18-6/24)", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "Rules"
                }), "\n", _jsx(_components.li, {
                  children: "Scorelimit: 35"
                }), "\n", _jsx(_components.li, {
                  children: "Time Limit: 10 minutes"
                }), "\n", _jsx(_components.li, {
                  children: "Increased health regen"
                }), "\n", _jsx(_components.li, {
                  children: "Skull Town, Zeus Station, Monument, Thunderdome, Wattson’s Pylon, Fragment"
                }), "\n"]
              }), "\n"]
            }), "\n", _jsxs(_components.li, {
              children: ["LTM Rotations", "\n", _jsxs(_components.ul, {
                children: ["\n", _jsx(_components.li, {
                  children: "5/24-5/28 Control: Production Yard, Thunderdome, Caustic, Barometer, Lava Siphon"
                }), "\n", _jsx(_components.li, {
                  children: "5/31-6/3 Control: Production Yard, Thunderdome, Caustic, Barometer, Lava Siphon"
                }), "\n", _jsx(_components.li, {
                  children: "6/11-6/17 Gun Run: Skull Town, Zeus Station, Thunderdome, Wattson, Fragment, The Core"
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "ranked",
      children: _jsx(_components.a, {
        href: "#ranked",
        children: "Ranked"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "All players in a premade Ranked squad must be within 3 Rank tiers of each other or they will not be allowed to progress to matchmaking"
        }), "\n", _jsx(_components.li, {
          children: "No tuning changes to be made to ranked scoring for the launch of Upheaval"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "season-reset",
        children: _jsx(_components.a, {
          href: "#season-reset",
          children: "Season Reset"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["Where you ended in your last season of Ranked will determine where you start in Upheaval", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "If you ended your last season in Rookie, you will be reset to 1 RP"
            }), "\n", _jsx(_components.li, {
              children: "If you ended your last season above Rookie, you will be reset to Bronze IV"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "split-timing",
        children: _jsx(_components.a, {
          href: "#split-timing",
          children: "Split Timing"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Split 2 will take place at the same time as the .1 patch, not a week after like in previous seasons"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "upheaval-ranked-rewards",
        children: _jsx(_components.a, {
          href: "#upheaval-ranked-rewards",
          children: "Upheaval Ranked Rewards"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Your end-of-season rewards will now be determined by the highest Rank tier you achieved during the entire season"
        }), "\n", _jsxs(_components.li, {
          children: ["Split Rewards: your season-end reward badge will be animated if you match or surpass your Split 1 Rank in Split 2", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "If you do not achieve this, you will get the normal version of your badge"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "bug-fixes",
      children: _jsx(_components.a, {
        href: "#bug-fixes",
        children: "Bug Fixes"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Equipping an Evac Tower or Mobile Respawn Beacon will no longer close the inventories of all other players in the match"
        }), "\n", _jsx(_components.li, {
          children: "Firing Range: fixed some edge cases where Legend change was available when it shouldn’t be"
        }), "\n", _jsx(_components.li, {
          children: "Fixed occasional crash when interacting with an enemy’s crafted banner"
        }), "\n", _jsx(_components.li, {
          children: "“Mischief Medic” no longer appears as “Highlighted Healer”"
        }), "\n", _jsx(_components.li, {
          children: "Olympus: players can no longer enter/exit Vault without key"
        }), "\n", _jsx(_components.li, {
          children: "Survey Beacons and Ring Consoles should now be pingable again from the map"
        }), "\n", _jsx(_components.li, {
          children: "When hip firing with the Devotion, it will now properly track its reticle"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "legends-1",
        children: _jsx(_components.a, {
          href: "#legends-1",
          children: "LEGENDS"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Ballistic: duration of speedy whistler restored to 2s"
        }), "\n", _jsxs(_components.li, {
          children: ["Bloodhound", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Passive markers no longer appear for teammates not on player’s squad"
            }), "\n", _jsx(_components.li, {
              children: "Players can once again be scanned by two Bloodhounds at the same time"
            }), "\n"]
          }), "\n"]
        }), "\n", _jsx(_components.li, {
          children: "Catalyst: fixed Dark Veil not charging for a short duration off of spawn"
        }), "\n", _jsx(_components.li, {
          children: "Crypto: recall audio when the drone is far away from you is audible once again"
        }), "\n", _jsx(_components.li, {
          children: "Maggie: Removed drill burn audio for players in the area of effect while phased"
        }), "\n", _jsx(_components.li, {
          children: "Removed Wraith Shadows from the void if you aren’t playing as Wraith"
        }), "\n", _jsx(_components.li, {
          children: "Wattson: resolved bad spawn points for Arc Stars generated from the Falling Stars upgrade"
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "quality-of-life",
      children: _jsx(_components.a, {
        href: "#quality-of-life",
        children: "Quality of Life"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Additional security improvements"
        }), "\n", _jsx(_components.li, {
          children: "Airdropping Replicators now project a beam upwards as they are descending to help differentiate them from other airdrops"
        }), "\n", _jsx(_components.li, {
          children: "Back by popular demand, you can requeue at the end of Pubs BR and Mixtape matches"
        }), "\n", _jsx(_components.li, {
          children: "Ballistic: can now add any locked-set weapon into the sling where it will be converted to the proper locked-set tier and restored to its original state when being moved out (red-tier still not allowed)"
        }), "\n", _jsx(_components.li, {
          children: "Death Box Flyers: option to automatically ping the location of the Death Box will be prompted when knocking it from a Flyer’s grasp"
        }), "\n", _jsx(_components.li, {
          children: "Improved the choice of consumables that are auto-selected when either reaching full health, reaching full shields, or when dropping your last selected item. The new choice should more intelligently select shield consumables or prioritize syringes for quick healing. These changes were made to help new players have more optimal outcomes. "
        }), "\n", _jsx(_components.li, {
          children: "Improved use interactions with doors when self res is available"
        }), "\n", _jsx(_components.li, {
          children: "Map spawn audit for all Mixtape Modes: Phase Runner, Habitat, Thunderdome, Zeus Station"
        }), "\n", _jsx(_components.li, {
          children: "Upgraded to the latest version of Easy Anti-Cheat"
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "pings",
        children: _jsx(_components.a, {
          href: "#pings",
          children: "Pings"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Should now go through all translucent surfaces like windows"
        }), "\n", _jsxs(_components.li, {
          children: ["Players can now request for Grenades (Arc Stars, Frag Grenades, and Thermites)", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Works similar to healing items: hold the Grenade button to open the Ordnance Wheel, hover on an ordnance item, select Ping to request"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), _jsx(_components.h4, {
        id: "thunderdome",
        children: _jsx(_components.a, {
          href: "#thunderdome",
          children: "Thunderdome"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Airdrop location adjustments"
        }), "\n", _jsxs(_components.li, {
          children: ["Control", "\n", _jsxs(_components.ul, {
            children: ["\n", _jsx(_components.li, {
              children: "Moved C capture point to landing pad"
            }), "\n", _jsx(_components.li, {
              children: "Adjusted spawns attached to B capture point"
            }), "\n"]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "graphics",
      children: _jsx(_components.a, {
        href: "#graphics",
        children: "Graphics"
      })
    }), "\n", _jsx(Card, {
      children: _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Added new \"Map Detail\" PC video setting to adjust the amount of environmental decoration and set dressing (this may improve performance for players with low-spec PCs and those targeting high framerates)"
        }), "\n", _jsx(_components.li, {
          children: "Changed the way players opt-in to the DX12 beta: if you're playing via the DirectX 12 beta now, the launch argument \"-eac_launcher_settings SettingsDX12.json\" should be changed to \"-anticheat_settings=SettingsDX12.json\""
        }), "\n", _jsx(_components.li, {
          children: "Improved accuracy and visual fidelity of baked environment lighting for static outdoor objects"
        }), "\n", _jsx(_components.li, {
          children: "Improved accuracy of baked lighting for dynamic objects, to avoid situations where Legends would appear unlit"
        }), "\n", _jsx(_components.li, {
          children: "Significantly improved CPU performance of the Rendering Hardware Interface (RHI), mostly benefitting the PC DirectX 12 beta"
        }), "\n"]
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
